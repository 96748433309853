import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import pageReducer from './pageReducer'
import messagesReducer from './messagesReducer'

const rootReducer = combineReducers({
  pages: pageReducer,
  messages: messagesReducer
})

export default rootReducer
