// Default react libraries
import React from 'react'

// Third party libraries
import { Link, useLocation } from 'react-router-dom'

import './menu.css'

const Menu = () => {

  const location = useLocation()

  return(
    <>
      <ul className='nav'>
        <li><Link to='/biografie' className={( location.pathname === '/biografie') ? 'active' : ''}>Wie</Link></li>
        <li><Link to='/wat' className={( location.pathname === '/wat') ? 'active' : ''}>Wat</Link></li>
        <li><Link to='/hoe' className={( location.pathname === '/hoe') ? 'active' : ''}>Hoe</Link></li>
        <li><Link to='/contact' className={( location.pathname === '/contact') ? 'active' : ''}>Contact</Link></li>
      </ul>
    </>
  )

}

export default Menu
