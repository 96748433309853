// Default react stuff
import React from 'react'
import { connect } from 'react-redux' // To access stuff in redux-store and to dispatch action creators

// Third party Components
import { Helmet } from 'react-helmet' // To put meta data in the meta tags
import Button from '@material-ui/core/Button' // To have the back button
import { Link } from 'react-router-dom'


// Custom components
import Home from '../Home/Home' // Home page with the panels
import Contact from '../Contact/Contact' // Contact form
// import PageNotFound from '../PageNotFound/PageNotFound' // To show when page not found

import { withRouter } from 'react-router-dom' // To have access to match, so we can get stuff from path

import './page.css'

// The object with all of the aformentioned pre-written components, which are imported
// contact: Contact
const staticContent = {
  home: Home,
  contact: Contact
}

// - pages & styles come from the redux-store
// - match comes from react-router-dom (which is connected with withRouter at the bottom too) and is used
//   to get the id parameter from the url so the proper page can be returned.
const Page = ({ pages, styles, match  }) => {

  // Here we check whether the page from the payload is in the object with pre-written components above.
  // The result is in the third line of the fragment, if it's in there, include the component.
  const page = match.params.page || 'home' // If no page can be gotten from the payload (upon initial load), 'home' is used.
  // console.log('page: ' + page)
  const StaticContent = staticContent[page]

  if(pages) {
    const currentPage = pages[page.toLowerCase()]
    if(page === 'home' || page === 'contact') {
      return <StaticContent />
    } else {
      return <span dangerouslySetInnerHTML={{__html: currentPage.content}}></span>
    }
  } else {
    return false
  }

  //
  // {StaticContent && <StaticContent />}


}

const mapState = ({ pages, styles }) => ({ pages, styles })
export default connect(mapState)(withRouter(Page))


// <Modal
//   isOpen={modalIsOpen}
//   onRequestClose={closeModal}
//   style={customStyles}
//   contentLabel="Example Modal"
// >
//   <h2>Hello</h2>
//   <button onClick={closeModal}>close</button>
//   <div>I am a modal</div>
//   <form>
//     <input />
//     <button>tab navigation</button>
//     <button>stays</button>
//     <button>inside</button>
//     <button>the modal</button>
//   </form>
// </Modal>


// <I18n label={currentPage.content} />


// Metadata: apparently keywords are useless, so just focus on the title and description
// https://www.youtube.com/watch?v=ClXTdjsXKFU
// if (!currentPage) {
//   return (
//     <>
//       <Helmet
//         style={[{
//           "cssText": `
//             #banner {
//               background-color: ` + styles.background_banner + `;
//             }
//           `
//         }]}
//       >
//       </Helmet>
//       <PageNotFound />
//     </>
//   )
// } else {
//   return (
//     <>
//       <Helmet
//         style={[{
//           "cssText": `
//             p {
//               font-family: ` + styles.font_paragraphs + `;
//             }
//             h1 {
//               font-family: ` + styles.font_titles + `;
//             }
//             body {
//               background-color: ` + styles.background_main + `;
//             }
//             #banner {
//               background-color: ` + styles.background_banner + `;
//             }
//             nav, #footer, .MuiAppBar-colorPrimary-8 {
//               background-color: ` + styles.background_menu + `;
//             }
//             .active {
//               background-color: ` + styles.background_active + `;
//             }
//             nav ul ul {
//               background-color: ` + styles.background_dropdown + `;
//             }
//           `
//         }]}
//       >
//         <title>{currentPage.meta_title}</title>
//         <meta
//           name="description"
//           content={currentPage.meta_description}
//         />
//       </Helmet>
//       {StaticContent && <StaticContent />}
//       { page !== 'home' ? <Button component={Link} to="/">Terug</Button> : null }
//     </>
//   )
// }
