// Default react stuff
import React from 'react'

// Third party libraries
import { Form, Field } from 'react-final-form'
import { connect } from 'react-redux' // to have dispatch
import Button from '@mui/material/Button'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import EmailIcon from '@mui/icons-material/Email'
import LanguageIcon from '@mui/icons-material/Language'
import LocationOnIcon from '@mui/icons-material/LocationOn'

// Custom Components
import SnazzyField from '../SnazzyField/SnazzyField'
import SnazzyTextArea from '../SnazzyTextArea/SnazzyTextArea'
import Popup from '../Popup/Popup' // To show stuff in the message queue in the snackbar

// Utils
import { required, email, composeValidators } from '../../utils/validations'

// Action creator
import { mailRequest } from '../../actions/contact'

// Styling
import './contact.css'

const Contact = ({ dispatch }) => {

  const onSubmit = (values, form, reset) => {
    dispatch(mailRequest(values))
    form.reset()
    form.resetFieldState('firstName')
    form.resetFieldState('lastName')
    form.resetFieldState('email')
    form.resetFieldState('message')
  }

  return(

    <>

      <h1>Contact</h1>

      <table>
        <tbody>
          <tr className='contactRow'><td className='contactIcon'><LocalPhoneIcon /></td><td> - </td><td>0473/46.33.22</td></tr>
          <tr className='contactRow'><td className='contactIcon'><EmailIcon /></td><td> - </td><td>contact@verelstwerner.be</td></tr>
          <tr className='contactRow'><td className='contactIcon'><LanguageIcon /></td><td> - </td><td>www.verelstwerner.be</td></tr>
          <tr className='contactRow'><td className='contactIcon'><LocationOnIcon /></td><td> - </td><td>Kuitenbergstraat 78, 9940 Evergem</td></tr>
        </tbody>
      </table>

      <br />

    { /* 
      
      <Form
        onSubmit={onSubmit}
        render={({ invalid, pristine, submitFailed, handleSubmit, form, submitting, values, reset }) => (

          <form onSubmit={handleSubmit}>
            <Field name='firstName' type='text' placeholder='Voornaam' component={SnazzyField} validate={required} />
            <Field name='lastName' type='text' placeholder='Achternaam' component={SnazzyField} validate={required} />
            <Field name='email' type='text' placeholder='Email' component={SnazzyField} validate={composeValidators(required, email)} />
            <Field name='message' type='text' placeholder='Bericht' component={SnazzyTextArea} validate={required} />

            <Button variant='contained' color='primary' type='submit'>Verzenden</Button>

            <br />
            <br />

            <span className='errorSubmit'>{ (invalid && submitFailed ? 'Gelieve de rode velden in te vullen' : '') }</span>

          </form>

        )}
      />  
      
        */ }

  
      
      
      

      <Popup />

    </>



  )


}

export default connect()(Contact)
