import firebase from 'firebase/compat/app'
import 'firebase/compat/database' // to get stuff from the database
import 'firebase/compat/auth'     // to be able to use the authorization stuff
import 'firebase/compat/storage'  // to upload and delete an image

// Initialize Firebase
const config = {
  apiKey: "AIzaSyD41vBK8GiURLe-UNgZFZDtXY-v7TY1I60",
  authDomain: "verelstwerner-e7b1a.firebaseapp.com",
  databaseURL: "https://verelstwerner-e7b1a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "verelstwerner-e7b1a",
  storageBucket: "verelstwerner-e7b1a.appspot.com",
  messagingSenderId: "282867502700",
  appId: "1:282867502700:web:2a1dd7aa41f4c54537d249",
  measurementId: "G-6XFDJSZQYF"
}

// this used to be const, but that doesn't make it out of the try to export
let app = undefined

try {
  app = firebase.initializeApp(config)
} catch (err) {
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack)
  }
}

// For uploading & deleting images
const storage = app.storage()

export { firebase, app, storage }
