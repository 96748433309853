// Default react stuff
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'

// Third party libraries
import Grid from '@material-ui/core/Grid'
// import Modal from 'react-modal'
import ScrollUpButton from 'react-scroll-up-button'

// Custom components
import Home from './components/Home/Home'
import Menu from './components/Menu'
import Banner from './components/Banner'
import Footer from './components/Footer/Footer'
import Loader from './components/Loader/Loader'
import Page from './components/Page/Page.js'

// Actions to be dispatched so the loading reducer and selector can pick up on them
import { PAGES_REQUEST } from './actions/types'

// Action creators
import { pagesRequest } from './actions/pages' // fetching the content of the pages
import { createLoadingSelector } from './selectors/createLoadingSelector' // to have loading state, during which Loader is displayed

// Custom styles
import './App.css';

// - dispatch comes from connect, which we use to dispatch action creators to pull in stuff from firebase, trigger a reducer into putting it into the redux-store
// - match comes from react-router-dom (which is connected with withRouter at the bottom too) and is used to get the lang parameter from the url
// - isFetching is a boolean value that indicates whether there is still something being fetched
const App = ({dispatch, match, isFetching}) => {

  useEffect(() => {
    dispatch(pagesRequest())
    dispatch({ type: PAGES_REQUEST })
  }, [dispatch])

  return (
    <>
      <Grid container direction="column" alignItems="center" className="App" wrap="nowrap" spacing={0}>
        <Grid item id="banner" xl={10} lg={10} md={11} sm={12} xs={12}>
          <Banner />
        </Grid>
        <Grid item id="menuContainer" xl={10} lg={10} md={11} sm={12} xs={12}>
          <Menu />
        </Grid>
        <Grid item id="mainContainer" xl={10} lg={10} md={11} sm={12} xs={12}>
          <Page />
        </Grid>
        <Grid item id="footer" xl={10} lg={10} md={11} sm={12} xs={12}>
          <Footer />
        </Grid>
      </Grid>
      <ScrollUpButton />
    </>
  )

}

// <Grid item id="footer" xl={10} lg={10} md={11} sm={12} xs={12}>
//   <Footer />
// </Grid>

// const loadingSelector = createLoadingSelector(['PAGES'])
// const mapState = (state) => ({ isFetching: loadingSelector(state) })
// export default connect(mapState)(withRouter(App))

export default connect()(withRouter(App))
