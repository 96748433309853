// Default react libraries
import React from 'react'

// Third party libraries
import { Carousel } from 'react-responsive-carousel'
import Grid from '@material-ui/core/Grid'

import './banner.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import pic1 from '../../img/carousel/beach.jpg'
import pic2 from '../../img/carousel/book.jpg'
import pic3 from '../../img/carousel/clouds.jpg'
import pic4 from '../../img/carousel/drop.jpg'
import pic5 from '../../img/carousel/handrain.jpg'
import pic6 from '../../img/carousel/ladybug.jpg'
import pic7 from '../../img/carousel/leaf.jpg'
import pic8 from '../../img/carousel/lights.jpg'
import pic9 from '../../img/carousel/pagefiller1.jpg'
import pic10 from '../../img/carousel/pagefiller2.jpg'
import pic11 from '../../img/carousel/stars.jpg'
import pic12 from '../../img/carousel/sun.jpg'

const Banner = () => (
  <Grid container direction="row" alignItems="center">
    <Grid item id="bannerPicture">
    <Carousel
      autoPlay={true}
      interval={3000}
      infiniteLoop={true}
      showStatus={false}
      showArrows={false}
      showThumbs={false}
    >
      <div><img src={pic1} /></div>
      <div><img src={pic2} /></div>
      <div><img src={pic3} /></div>
      <div><img src={pic4} /></div>
      <div><img src={pic5} /></div>
      <div><img src={pic6} /></div>
      <div><img src={pic7} /></div>
      <div><img src={pic8} /></div>
      <div><img src={pic9} /></div>
      <div><img src={pic10} /></div>
      <div><img src={pic11} /></div>
      <div><img src={pic12} /></div>
    </Carousel>
    </Grid>
  </Grid>
)

export default Banner
