// Default react stuff
import React, { useState } from 'react'

// Third party libraries
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded'
import ExploreRoundedIcon from '@mui/icons-material/ExploreRounded'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'

// Logo
import logo from '../../img/logo.png'

// Styling
import './home.css'

const Home = () => {

  return(
    <div id='wrapper'>

      <div id='bg'></div>

      <div id='overlay'></div>

      <div id="main">
          <header id="header">
              <img src={logo} className='logo' />
              <nav>
                  <ul>
                      <li>
                        <Link to='/biografie'>
                          <p data-tip="Wie"><AccountCircleIcon fontSize='large' /></p>
                        </Link>
                      </li>
                      <li>
                        <Link to='/wat'>
                          <p data-tip="Wat"><AutoStoriesRoundedIcon fontSize='large' /></p>
                        </Link>
                      </li>
                      <li>
                        <Link to='/hoe'>
                          <p data-tip="Hoe"><ExploreRoundedIcon fontSize='large' /></p>
                        </Link>
                      </li>
                      <li>
                        <Link to='/contact'>
                          <p data-tip="Contact"><EmailRoundedIcon fontSize='large' /></p>
                        </Link>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/verelstwerner/" target="_blank">
                          <p data-tip="Facebook"><FacebookRoundedIcon fontSize='large' /></p>
                        </a>
                      </li>
                  </ul>
              </nav>
              <ReactTooltip
                place='top'
                border={true}
                backgroundColor='#e0dcdc'
                textColor='#333231'
                delayShow={50}
                effect='float'
                className='tooltip'
              />
          </header>
      </div>

      <footer id="footerLanding">
          <span className="copyright">&copy; Website door: <a href="https://www.webcondor.be" target="_blank" className='linkToWebcondor'>Webcondor</a></span>
      </footer>


    </div>
  )
}

export default Home
