// Default react stuff
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom' // Instead of React-First-Router we now use React-Router, to manage everything with regards to the routes

// Material UI
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'

// Custom components
import App from './App'
import Home from './components/Home/Home'

// Utilities
import configureStore from './utils/configureStore' // The configuring of the redux store (global state of application), the nodes where the reducers write stuff when triggered by actions
import history from './utils/history' // Managing the history stack

// . current directory
// .. parent directory
// And with .env being deprecated.....

// Custom styles
import './index.css'

const store = configureStore() // Creation of an instance of the redux store and browser history

const THEME = createTheme({
  palette: {
    primary: {
      main: '#194759',
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ThemeProvider theme={THEME}>
    <Provider store={store}>
      <BrowserRouter>
        <>
          <Switch>
            <Route path="/:page" component={App} />
            <Route exact path="/" component={Home} />
          </Switch>
        </>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
)
