// Pages
export const PAGES_REQUEST = 'PAGES_REQUEST'
export const PAGES_SUCCESS = 'PAGES_SUCCESS'
export const PAGE = 'PAGE' // for pages with only dynamic content from the db

// Contact
export const MAIL_SUCCESS = 'MAIL_SUCCESS'

// Messages / feedback / popups
export const MESSAGE_ADD_REQUEST = 'MESSAGE_ADD_REQUEST'
export const MESSAGE_REMOVE_REQUEST = 'MESSAGE_REMOVE_REQUEST'
// From the errors I removed REQUEST, because this doesn't entail communication with
// the server and shouldn't be picked up by the loading thingy
export const ERROR_REMOVE = 'ERROR_REMOVE'
