// Default react libraries
import React from 'react'

// Custom Styling
import './footer.css'

import facebook from '../../img/socialMedia/facebook.png'
import linkedIn from '../../img/socialMedia/linkedin.png'

const Footer = () => {

  return(
    <>
      <span id="footerCopyright">© 2017 - Werner Verelst</span>
      <div id="externalLinks">
        <div id="socialMediaBtns">
          <a href="https://www.facebook.com/verelstwerner/" target="_blank"><img src={facebook} alt="facebook" /></a>
          <a href="https://www.linkedin.com/in/wernerverelst/" target="_blank"><img src={linkedIn} alt="linkedin" /></a>
        </div>
        <div id="webCondor">
          <a href="https://www.webcondor.be" target="_blank">Website by Webcondor</a>
        </div>
      </div>
    </>
  )

}

export default Footer
